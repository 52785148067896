import { useEffect, useRef, useState } from "react";
import Hls from "hls.js";

type Source = { src: string; type: string };

type Props = {
  sources: Source[];
};

export const PlaylistPlayer = ({ sources }: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [index, setIndex] = useState(0);
  const hlsRef = useRef<Hls | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showControls, setShowControls] = useState(false);

  useEffect(() => {
    const videoEl = videoRef.current;
    if (!videoEl || !sources[index]) return;

    // Clean up any existing Hls instance
    if (hlsRef.current) {
      hlsRef.current.destroy();
      hlsRef.current = null;
    }

    const currentSource = sources[index];
    if (currentSource.type === "application/x-mpegURL") {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(currentSource.src);
        hls.attachMedia(videoEl);
        hlsRef.current = hls;
        hls.on(Hls.Events.ERROR, (event, data) => {
          console.error("HLS error", event, data);
        });
      } else if (videoEl.canPlayType("application/vnd.apple.mpegurl")) {
        videoEl.src = currentSource.src;
      } else {
        console.error("HLS is not supported in this browser");
      }
    } else if (videoEl.canPlayType(currentSource.type)) {
      videoEl.src = currentSource.src;
    } else {
      console.error("This browser cannot play the stream:", currentSource);
    }

    const handleTimeUpdate = () => {
      setCurrentTime(videoEl.currentTime);
    };

    const handleLoadedMetadata = () => {
      setDuration(videoEl.duration);
    };

    const handleEnded = () => {
      if (sources[index + 1]) {
        setIndex(index + 1);
      }
    };

    videoEl.addEventListener("timeupdate", handleTimeUpdate);
    videoEl.addEventListener("loadedmetadata", handleLoadedMetadata);
    videoEl.addEventListener("ended", handleEnded);

    return () => {
      videoEl.removeEventListener("timeupdate", handleTimeUpdate);
      videoEl.removeEventListener("loadedmetadata", handleLoadedMetadata);
      videoEl.removeEventListener("ended", handleEnded);
      if (hlsRef.current) {
        hlsRef.current.destroy();
        hlsRef.current = null;
      }
    };
  }, [index, sources]);

  const progressPercentage = duration ? currentTime / duration : 0;
  const volume = videoRef?.current?.volume ? videoRef.current.volume : 0;

  const togglePlayPause = () => {
    const videoEl = videoRef.current;
    if (!videoEl) return;

    if (videoEl.paused) {
      videoEl.play();
    } else {
      videoEl.pause();
    }
  };

  const seekForward = () => {
    const videoEl = videoRef.current;
    if (!videoEl) return;

    videoEl.currentTime += 4.0;
  };

  const seekBackward = () => {
    const videoEl = videoRef.current;
    if (!videoEl) return;

    videoEl.currentTime -= 4.0;
  };

  const nextVideo = () => {
    if (index + 1 < sources.length) {
      setIndex(index + 1);
    } else {
      setIndex(0);
    }
  };

  const previousVideo = () => {
    if (index - 1 >= 0) {
      setIndex(index - 1);
    } else {
      setIndex(sources.length - 1);
    }
  };

  const seekPercent = (value: number) => {
    const videoEl = videoRef.current;
    if (!videoEl) return;

    if (duration) {
      videoEl.currentTime = value * duration;
    }
  };

  return (
    <div className="w-screen h-screen">
      <video
        ref={videoRef}
        autoPlay
        muted
        style={{ width: "100%", height: "100%" }}
        onMouseMove={(ev) => {
          ev.preventDefault();
          const rect = ev.currentTarget.getBoundingClientRect();
          const offsetY = rect.bottom - ev.clientY;

          setShowControls(offsetY < 80);
        }}
      />

      {showControls && (
        <div className="fixed flex-col gap-2 overflow-hidden z-50 bg-white w-full h-9 bottom-0 left-0 right-0 rounded-t-lg flex items-center">
          <Timeline
            percentage={progressPercentage}
            setPercentTime={seekPercent}
          />
          <div className="fixed flex-row z-50 h-9 w-full bottom-0 left-0 right-0 rounded-t-lg flex items-center">
            <button
              onClick={togglePlayPause}
              className="ml-4 group rounded-full hover:bg-[#222] transition-colors p-1.5 outline-none border-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                className="w-3 h-3 fill-[#111] transition-colors group-hover:fill-white"
              >
                {videoRef.current?.paused === true ? (
                  <path d="M275-248v-464q0-29.85 20.64-48.92Q316.29-780 343.48-780q8.68 0 18.1 2.5Q371-775 380-770l365 233q16.5 9 24.25 24.84T777-480q0 16.32-8 32.16Q761-432 745-423L380-190q-9 5-18.64 7.5t-18.22 2.5q-26.85 0-47.5-19.08Q275-218.15 275-248Z" />
                ) : (
                  <path d="M675.48-128q-56.48 0-95.98-39.31Q540-206.63 540-264v-433q0-55.97 39.32-95.99Q618.64-833 676.02-833 732-833 772-792.99q40 40.02 40 95.99v433q0 57.37-40.02 96.69Q731.96-128 675.48-128Zm-391.5 0Q228-128 188-167.31q-40-39.32-40-96.69v-433q0-55.97 40.02-95.99Q228.04-833 284.52-833t95.98 40.01Q420-752.97 420-697v433q0 57.37-39.32 96.69Q341.36-128 283.98-128Z" />
                )}
              </svg>
            </button>

            <button
              onClick={seekBackward}
              className="ml-4 group rounded-full hover:bg-[#222] transition-colors p-1.5 outline-none border-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                className="w-3 h-3 fill-[#111] transition-colors group-hover:fill-white"
              >
                <path d="m225-520 138 137q19 20 19 48t-20 48q-20 19-48 19t-47-19L82-472q-9-10-14.5-22.5T62-520q0-14 5.5-26.5T82-569l185-184q19-20 46.5-20t48.5 20q20 19 20 47t-20 49L225-520Zm345 68 70 70q20 19 20 47.5T640-287q-19 20-47 19.5T546-287L361-472q-10-10-15.5-22.5T340-520q0-14 5.5-26.5T361-569l185-185q19-19 47-19t47 19q20 20 20 48t-19 47l-71 71h127q95 0 162 67t67 162v137q0 29-19.5 48.5T858-154q-28 0-48-19.5T790-222v-137q0-40-27-66.5T697-452H570Z" />
              </svg>
            </button>

            <button
              onClick={seekForward}
              className="ml-4 group rounded-full hover:bg-[#222] transition-colors p-1.5 outline-none border-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                className="w-3 h-3 fill-[#111] transition-colors group-hover:fill-white"
              >
                <path d="M735-520 598-657q-20-21-20-49t20-47q21-20 48.5-20t46.5 20l185 184q9 10 14.5 22.5T898-520q0 13-5.5 25.5T878-472L693-287q-19 19-47 19t-48-19q-20-20-20-48t19-48l138-137Zm-345 68H263q-39 0-66 26.5T170-359v137q0 29-20 48.5T102-154q-29 0-48.5-19.5T34-222v-137q0-95 67-162t162-67h127l-71-71q-19-19-19-47t20-48q19-19 47-19t47 19l185 185q10 10 15.5 22.5T620-520q0 13-5.5 25.5T599-472L414-287q-19 19-47 19.5T320-287q-20-19-20-47.5t20-47.5l70-70Z" />{" "}
              </svg>
            </button>

            <button
              onClick={previousVideo}
              className="ml-4 group rounded-full hover:bg-[#222] transition-colors p-1.5 outline-none border-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                className="w-3 h-3 fill-[#111] transition-colors group-hover:fill-white"
              >
                <path d="M177-197v-566h91v566h-91Zm606 0L372-480l411-283v566Z" />
              </svg>
            </button>

            <button
              onClick={nextVideo}
              className="ml-4 group rounded-full hover:bg-[#222] transition-colors p-1.5 outline-none border-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                className="w-3 h-3 fill-[#111] transition-colors group-hover:fill-white"
              >
                <path d="M692-197v-566h91v566h-91Zm-515 0v-566l411 283-411 283Z" />
              </svg>
            </button>

            <VolumeSlide
              percentage={volume}
              setPercentage={(value) => {
                const videoEl = videoRef.current;
                if (!videoEl) return;

                if (duration) {
                  videoEl.volume = value;
                }
              }}
              muted={videoRef.current?.muted}
              toggleMute={() => {
                const videoEl = videoRef.current;
                if (!videoEl) return;

                if (duration) {
                  videoEl.muted = !videoEl.muted;
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

type TimelineProps = {
  percentage: number;
  setPercentTime: (value: number) => void;
};

function Timeline({ percentage, setPercentTime }: TimelineProps) {
  const handleMouseDown = (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    ev.preventDefault();
    if (ev.currentTarget?.clientWidth) {
      const percent = ev.clientX / ev.currentTarget.clientWidth;
      setPercentTime(percent);
    }
  };

  const x_pos = percentage * 1000;

  return (
    <button
      onMouseDown={handleMouseDown}
      className="group flex flex-row w-full p-0 m-0 border-none outline-none"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="6px"
        viewBox="0 0 1000 6"
        preserveAspectRatio="none"
        className="cursor-pointer"
        pointerEvents="auto"
      >
        <defs>
          <linearGradient id="tealGradient" x1="0%" y1="50%" x2="100%" y2="50%">
            <stop offset="0%" stopColor="#0d9488" />
            <stop offset="100%" stopColor="#5eead4" />
          </linearGradient>
        </defs>

        {/* Full clickable background */}
        <rect
          width="1000"
          height="6"
          x="0"
          y="0"
          fill="rgba(0, 0, 0, 0.2)"
          className="cursor-pointer"
        />

        {/* Progress bar */}
        <rect fill="url(#tealGradient)" width={x_pos} height="6" x="0" y="0" />

        <circle
          cx={x_pos}
          cy="3" // vertically centered (6px height / 2)
          r="5"
          fill="#000"
          className="opacity-0 group-hover:opacity-100 transition-opacity duration-200"
        />
      </svg>
    </button>
  );
}

type VolumeSlideProps = {
  percentage: number;
  setPercentage: (value: number) => void;
  muted: boolean;
  toggleMute: () => void;
};

function VolumeSlide({
  percentage,
  setPercentage,
  muted,
  toggleMute,
}: VolumeSlideProps) {
  const handleMouseDown = (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    ev.preventDefault();
    const rect = ev.currentTarget.getBoundingClientRect();
    const offsetX = ev.clientX - rect.left;
    const percent = Math.min(Math.max(offsetX / rect.width, 0), 1);

    setPercentage(percent);
  };

  const x_pos = percentage * 128;

  return (
    <div className="flex-row flex items-center ml-auto mr-12 gap-2">
      <button
        onClick={toggleMute}
        className="ml-4 group rounded-full hover:bg-[#222] transition-colors p-1.5 outline-none border-none"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -960 960 960"
          className="w-3 h-3 fill-[#111] transition-colors group-hover:fill-white"
        >
          {muted ? (
            <path d="M691-128q-8 6-16.5 10t-16.5 8q-20 8-39.5-2T592-144q-4-15 1-30t16-27q2-1 4-1.5t4-1.5L497-326v102q0 45-42 62.5T381-177L240-318H122q-28 0-48-20t-20-48v-188q0-28 20-48t48-20h69L40-798q-15-15-14.5-36.5T41-871q16-15 37-15t36 16l732 750q15 16 15.5 36.5T846-47q-16 16-37.5 16T771-47l-80-81Zm106-353q0-88-47.5-160T624-750q-19-9-27-28t-1-39q8-21 27.5-30.5T663-849q107 48 173 147t66 221q0 30-5 58t-13 56q-9 29-29 37t-39 2q-20-5-31.5-22.5T781-391q7-21 11-44t5-46ZM618-633q34 27 55.5 62.5T697-490v1q0 1 1 1 0 17-18 23.5t-32-8.5l-51-52q-10-10-15-22.5t-5-25.5v-37q0-16 13.5-24t27.5 1ZM386-743q-10-10-9.5-23t9.5-22q32-33 71.5-13.5T497-736v27q0 22-20.5 30t-35.5-7l-55-57Z" />
          ) : percentage < 0.4 ? (
            <path d="M334-318H217q-29 0-48.5-20T149-386v-188q0-28 19.5-48t48.5-20h117l141-141q32-33 74.5-15.5T592-736v512q0 45-42.5 62.5T475-177L334-318Zm457-162q0 45-20.5 84.5T713-328q-14 8-27.5 0T672-352v-257q0-17 13.5-24t27.5 1q37 28 57.5 67.5T791-480Z" />
          ) : (
            <path d="M798-481q0-88-47-160T625-750q-19-9-26.5-28t-.5-39q7-21 27-30.5t39-1.5q109 49 174 148t65 220q0 120-65 219T664-114q-19 9-39-1t-27-31q-7-20 .5-39t26.5-28q79-37 126-109t47-159ZM242-318H125q-29 0-48.5-20T57-386v-188q0-28 19.5-48t48.5-20h117l141-141q33-33 75-15.5t42 62.5v512q0 45-42 62.5T383-177L242-318Zm457-162q0 45-20.5 84.5T621-328q-14 8-27.5 0T580-352v-257q0-17 13.5-24t27.5 1q37 28 57.5 67.5T699-480Z" />
          )}
        </svg>
      </button>

      <button
        onMouseDown={handleMouseDown}
        className="group flex flex-row w-[128px] p-0 m-0 border-none outline-none"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="128px"
          height="6px"
          viewBox="0 0 128 6"
          preserveAspectRatio="none"
          className="cursor-pointer"
          pointerEvents="auto"
        >
          <defs>
            <linearGradient
              id="tealGradient"
              x1="0%"
              y1="50%"
              x2="100%"
              y2="50%"
            >
              <stop offset="0%" stopColor="#0d9488" />
              <stop offset="100%" stopColor="#5eead4" />
            </linearGradient>
          </defs>

          {/* Full clickable background */}
          <rect
            width="128"
            height="6"
            x="0"
            y="0"
            rx="3px"
            ry="3px"
            fill="rgba(0, 0, 0, 0.2)"
            className="cursor-pointer"
          />

          {/* Progress bar */}
          <rect
            fill={muted ? "#444" : "url(#tealGradient)"}
            width={x_pos}
            height="6"
            rx="3px"
            ry="3px"
            x="0"
            y="0"
          />

          <circle
            cx={x_pos}
            cy="3" // vertically centered (6px height / 2)
            r="5"
            fill="#000"
            className="opacity-0 group-hover:opacity-100 transition-opacity duration-200"
          />
        </svg>
      </button>
    </div>
  );
}
